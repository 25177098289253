<template>
    <b-modal
      v-model="data.showModal"
      size="xl"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          <div class="flex gap-2">
            <span>Edit Sample -</span>
            <span v-if="sample">{{ sample.sample_number }}</span>
          </div>
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <div v-if="sample" class="flex flex-col gap-4">
          <div class="grid grid-cols-3 gap-2">
            <div>
              <table class="table table-google">
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="row-fit">ID</td>
                    <td>{{ sample.id }}</td>
                  </tr>

                  <tr>
                    <td class="row-fit text-nowrap">Sample Number</td>
                    <td>
                      <b-form-input
                        v-model="sample.sample_number"
                        autocomplete="off"
                        autofocus
                        size="sm"
                        trim
                      ></b-form-input>
                    </td>
                  </tr>

                  <tr>
                    <td class="row-fit text-nowrap">Sample Date</td>
                    <td>
                      <date-picker
                        class="date-picker"
                        v-model="sample.sample_date"
                        format="DD.MM.YYYY"
                        valueType="YYYY-MM-DD"
                        :clearable="false"
                        :lang="datepickerLocale"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td class="row-fit text-nowrap">Scale</td>
                    <td>{{ sample.device_description }} - {{ sample.device_model }}</td>
                  </tr>

                  <tr>
                    <td class="row-fit text-nowrap">Burn Duration</td>
                    <td>
                      <div v-if="burnDuration === 0">Not available</div>
                      <div v-else>{{ formatDuration(burnDuration) }}</div>
                    </td>
                  </tr>

                  <tr>
                    <td class="row-fit text-nowrap">Starting Weight</td>
                    <td>
                      <div v-if="sample.weight_start_id" class="flex gap-1">
                        <div>
                          {{ sample.weight_start_ts | longdate }}
                          {{ sample.weight_start_ts | time }}
                        </div>
                        <div>-</div>
                        <div class="font-bold">{{ sample.weight_start_value | round }} g</div>
                      </div>
                      <div v-else>Hasn't started yet</div>
                    </td>
                  </tr>

                  <tr>
                    <td class="row-fit text-nowrap">Ending Weight</td>
                    <td>
                      <div v-if="sample.weight_end_id" class="flex gap-1">
                        <div>
                          {{ sample.weight_end_ts | longdate }}
                          {{ sample.weight_end_ts | time }}
                        </div>
                        <div>-</div>
                        <div class="font-bold">{{ sample.weight_end_value | round }} g</div>
                      </div>
                      <div v-else-if="sample.weight_start_id">
                        In Progress
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-span-2 flex flex-col gap-4">
              <div class="grid grid-cols-4 gap-4">
                <div>
                  <div>Before Burn Front</div>
                  <image-box :data="sample" field="preburn_front_file_id" />
                </div>

                <div>
                  <div>Before Burn Top</div>
                  <image-box :data="sample" field="preburn_top_file_id" />
                </div>

                <div>
                  <div>After Burn Front</div>
                  <image-box :data="sample" field="postburn_front_file_id" />
                </div>

                <div>
                  <div>After Burn Top</div>
                  <image-box :data="sample" field="postburn_top_file_id" />
                </div>
              </div>
              <div>
                <video-box
                  :data="sample"
                  field="video_file_id"
                  style="max-height: 350px;"
                />
              </div>
            </div>

          </div>

          <div class="hidden">
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading"
              @click="generateGraphData"
            >
              Generate graphs
            </b-btn>
          </div>

          <div v-if="sample.graph_data">
            <graph-data :data="sample.graph_data" :startTs="sample.weight_start_ts" />
          </div>
          <div v-else-if="graphData">
            <graph-data :data="graphData" :startTs="sample.weight_start_ts" />
          </div>
          <div v-else class="flex gap-2 items-center">
            <i class="fas fa-exclamation-triangle text-warning"></i>
            <div>Graph data not available</div>
          </div>

          <div>
            <h3 class="text-xl">Files</h3>
            <table class="table table-google">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>File</th>
                  <th class="text-right">
                    <b-btn
                      size="sm"
                      variant="primary"
                      :disabled="files === null || isLoading"
                      @click="selectFile()"
                    >
                      Upload
                    </b-btn>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="files === null">
                  <td colspan="3">Loading files..</td>
                </tr>
                <tr v-else-if="files.length === 0">
                  <td colspan="3">No files available</td>
                </tr>
                <tr v-else v-for="file in files" :key="file.id">
                  <td class="row-fit">{{ file.upload_ts | longdate }}</td>
                  <td>
                    <file-link :file="file" />
                  </td>
                  <td class="row-fit">
                    <div class="flex gap-2">
                      <b-btn
                        size="sm"
                        variant="primary"
                        :disabled="isLoading"
                        @click="renameFile(file)"
                      >
                        Rename
                      </b-btn>

                      <b-btn
                        size="sm"
                        variant="danger"
                        :disabled="isLoading"
                        @click="deleteFile(file)"
                      >
                        Delete
                      </b-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-else-if="isLoading">
          Loading..
        </div>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="onSave"
            >
              Save
            </b-btn>
          </div>
          <div class="flex gap-2">
            <b-btn
              size="sm"
              variant="danger"
              :disabled="isLoading"
              @click="onDelete"
            >
              Delete
            </b-btn>

            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>

        <input
          ref="file"
          type="file"
          :disabled="isLoading"
          @change="uploadFile()"
          class="hidden"
        >
      </div>
    </b-modal>
  </template>

<script>
import {
  datepickerLocale,
  formatDuration,
} from '@/helpers';

const FileLink = () => import('@/components/FileLink.vue');
const GraphData = () => import('@/components/scale/GraphData.vue');
const ImageBox = () => import('@/components/global/ImageBox.vue');
const VideoBox = () => import('@/components/global/VideoBox.vue');

export default {
  name: 'SampleEditor',
  components: {
    FileLink,
    GraphData,
    ImageBox,
    VideoBox,
  },
  props: {
    data: Object,
  },
  computed: {
    burnDuration() {
      const now = parseInt(new Date().getTime() / 1000, 10);
      const startTime = this.sample.weight_start_ts ?? now;
      const endTime = this.sample.weight_end_ts ?? now;
      const burnDuration = endTime - startTime;
      return burnDuration;
    },
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (!this.sample) return false;
      if (this.isLoading) return false;
      if (this.sample.sample_number.length === 0) return false;
      return true;
    },
    datepickerLocale() {
      return datepickerLocale;
    },
  },
  data() {
    return {
      loadingCount: 0,
      sample: null,
      graphData: null,
      filters: {},
      options: {},
      files: null,
    };
  },
  methods: {
    formatDuration(seconds) {
      return formatDuration(seconds);
    },
    fetchSample() {
      this.loadingCount++;
      this.$http
        .get(`/lifetime_sample/samples/${this.data.sampleId}`)
        .then((res) => {
          this.sample = res.body.sample;
          if (this.sample.graph_data === null) {
            this.fetchGraphData();
          }
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchFiles() {
      this.loadingCount++;
      this.$http
        .get(`/lifetime_sample/samples/${this.data.sampleId}/files`)
        .then((res) => {
          this.files = res.body.files;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch sample files: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchGraphData() {
      this.loadingCount++;
      this.$http
        .get(`/lifetime_sample/samples/${this.data.sampleId}/graphData`)
        .then((res) => {
          this.graphData = res.body.graphData;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch graph data: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    updateSample() {
      this.loadingCount++;
      this.$http
        .put(`/lifetime_sample/samples/${this.data.sampleId}`)
        .send({ changes: this.sample })
        .then((res) => {
          this.$emit('updated', res.body.sample);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to update sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    generateGraphData() {
      this.loadingCount++;
      this.$http
        .post('/lifetime_sample/generateGraphData')
        .send({ sampleId: this.data.sampleId })
        .send({ changes: this.sample })
        .then((res) => {
          this.sample.graph_data = res.body.sample.graph_data;
          this.$emit('updated', res.body.sample);
        })
        .catch((err) => {
          this.$toast.error(`Failed to update sample graphs: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    selectFile() {
      this.$refs.file.click();
    },
    uploadFile() {
      const formData = new FormData();
      formData.append('file', this.$refs.file.files[0]);
      formData.append('mtime', this.$refs.file.files[0].lastModified / 1000);
      this.$http
        .post(`/lifetime_sample/samples/${this.data.sampleId}/files`)
        .send(formData)
        .then((res) => {
          this.files.push(res.body.file);
        })
        .catch((err) => {
          this.$toast.error(`Failed to upload file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    renameFile(renameFile) {
      const stem = prompt('Rename file', renameFile.stem);
      if (stem === null || stem === renameFile.stem) {
        return;
      }
      this.loadingCount++;
      this.$http
        .put(`/lifetime_sample/samples/${this.data.sampleId}/files/${renameFile.id}`)
        .send({ stem })
        .then(() => {
          this.fetchFiles();
        })
        .catch((err) => {
          this.$toast.error(`Failed to rename sample file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deleteFile(deleteFile) {
      if (!confirm(`Do you really wish to delete '${deleteFile.filename}'?`)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/lifetime_sample/samples/${this.data.sampleId}/files/${deleteFile.id}`)
        .then(() => {
          this.files = this.files.filter((file) => file.id !== deleteFile.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete sample file: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchSample();
      this.fetchFiles();
    },
    onSave() {
      this.updateSample();
    },
    onShow() {
      this.fetchAll();
    },
    onDelete() {
      if (!confirm('Do you really wish to delete this sample?')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/lifetime_sample/samples/${this.data.sampleId}`)
        .then(() => {
          this.$emit('deleted', this.sample);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
